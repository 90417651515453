@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* .forge-block {
        @apply bg-white;
        @apply shadow;
        @apply rounded;
        @apply mx-2;
        @apply sm:mx-4;
        @apply py-6;
        @apply px-2;
        @apply sm:px-6;
        @apply lg:px-8;
        @apply mb-1;
    } */

    .forge-inner-block {
        @apply py-6;
    }

    .forge-link {
        @apply underline;
        @apply hover:cursor-pointer;
        @apply hover:text-blue-600;
    }

    .forge-button {
        @apply px-3;
        @apply py-1;
        @apply bg-blue-900;
        @apply border;
        @apply border-blue-900;
        @apply hover:bg-indigo-700;
        @apply text-white;
        @apply hover:text-gray-100;
        @apply focus:text-gray-300;
        @apply rounded-md;
        @apply inline-flex;
        @apply items-center;
        @apply text-sm;
        @apply focus:outline-none;
        @apply focus:ring-2;
        @apply focus:ring-offset-2;
        @apply focus:ring-offset-gray-600;
        @apply focus:ring-gray-400;

        @apply disabled:opacity-30;
        @apply disabled:cursor-not-allowed;
    }

    .forge-button-secondary {
        @apply forge-button;
        @apply bg-gray-100;
        @apply border-gray-300;
        @apply hover:bg-gray-300;
        @apply hover:border-gray-300;
        @apply text-gray-500;
        @apply hover:text-gray-600;
        @apply focus:text-gray-700;
    }

    .forge-button-tertiary {
        @apply forge-button-secondary;
        @apply bg-white;
        @apply hover:bg-gray-100;
    }

    .forge-button-inline {
        @apply forge-button-secondary;
        @apply border-transparent;
        @apply bg-white;
        @apply hover:bg-gray-100;
    }

    .forge-button-inline-inactive {
        @apply forge-button-inline;
        pointer-events: none;
    }

    .forge-button-danger {
        @apply forge-button;
        @apply bg-white;
        @apply border-red-700;
        @apply hover:bg-red-700;
        @apply text-red-700;
        @apply hover:text-white;
        @apply focus:text-white;
        @apply focus:bg-red-700;

        @apply disabled:border-gray-500;
        @apply disabled:text-gray-600;
        @apply disabled:bg-white;
        @apply disabled:cursor-not-allowed;
    }

    .forge-button-small {
        @apply px-2;
        @apply py-1;
        @apply text-xs;
    }


    .forge-button-set > :first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }
    .forge-button-set > :last-child button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    .forge-nav-item {
        @apply flex items-center;
        @apply pb-3;
        @apply border-b-4 border-b-transparent;
    }
    .forge-nav-item:not(.forge-nav-item-active) {
        @apply text-sm;
    }
    .forge-nav-item:not(.forge-nav-item-active):hover {
        @apply text-blue-700;
        @apply border-b-4;
        @apply border-gray-400;
    }

    .forge-nav-item-active {
        @apply text-sm;
        @apply text-blue-700;
        @apply border-b-4;
        @apply border-blue-700;
    }
    .forge-badge {
        @apply border;
        @apply rounded-full;
        @apply text-xs;
        @apply px-2;
        @apply py-1;
        @apply inline-flex;
        @apply items-center;
    }
    .forge-status-error,
    .forge-status-crashed {
        @apply bg-red-100;
        @apply border-red-400;
        @apply text-red-600;
    }
    .forge-status-suspended {
        @apply bg-red-200;
        @apply border-red-400;
        @apply text-red-600;
    }
    .forge-status-stopped {
        @apply bg-gray-100;
        @apply border-gray-300;
        @apply border-dashed;
        @apply text-gray-700;
    }
    .forge-status-info {
        @apply bg-gray-100;
        @apply border-gray-300;
        @apply border-dashed;
        @apply text-gray-800;
    }
    .forge-status-starting {
        @apply bg-green-100;
        @apply border-green-300;
        @apply border-dashed;
        @apply text-green-700;
    }
    .forge-status-safe {
        @apply bg-yellow-200;
        @apply border-yellow-400;
        @apply text-yellow-600;
    }
    .forge-status-warning {
        @apply bg-yellow-400;
        @apply border-yellow-700;
        @apply text-yellow-900;
    }
    .forge-status-success,
    .forge-status-connected,
    .forge-status-running {
        @apply bg-green-200;
        @apply border-green-400;
        @apply text-green-700;
    }
    .forge-status-importing {
        @apply bg-green-100;
        @apply border-green-300;
        @apply border-dashed;
        @apply text-green-700;
    }
    
    .forge-badge-devmode {
        @apply bg-purple-100;
        @apply border-purple-600;
        @apply text-purple-700;
    }
    .forge-badge-fleetmode {
        @apply bg-teal-100;
        @apply border-teal-600;
        @apply text-teal-700;
    } 

    th {
        @apply text-left;
        @apply font-medium;
    }

    input[type="text"],
    input[type="password"],
    input[type="radio"],
    input[type="checkbox"],
    select,
    textarea,
    .uneditable {
        @apply text-sm;
        @apply appearance-none;
        @apply rounded;
        @apply relative;
        @apply font-normal;
        @apply px-2;
        @apply py-1;
        @apply border;
        @apply border-gray-300;
        @apply placeholder-gray-500;
        @apply text-gray-600;
    }
    input[type="radio"],
    input[type="checkbox"] {
        @apply mr-2;
        @apply p-2;
    }
    .uneditable {
        @apply border-opacity-0;
    }

    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="radio"]:focus,
    input[type="checkbox"]:focus,
    select:focus,
    textarea:focus {
        @apply outline-none;
        @apply ring-0;
        @apply border-indigo-500;
    }
    input[type="text"]:disabled,
    input[type="password"]:disabled,
    input[type="radio"]:disabled,
    input[type="checkbox"]:disabled,
    select:disabled,
    textarea:disabled {
        @apply cursor-not-allowed;
        @apply opacity-60;
    }

    /* Make sure forge-log-entry-level-* all appear in tailwind.config.js safelist */
    .forge-log-entry-level-system {
        @apply text-blue-400;
    }
    .forge-log-entry-level-info {
        @apply text-gray-100;
    }
    .forge-log-entry-level-warn {
        @apply text-yellow-300;
    }
    .forge-log-entry-level-error {
        @apply text-red-400;
    }
}

@layer components {
    .ff-layout--box--left,
    .ff-layout--box--right {
        @apply p-0 md:p-12;
    }
    .ff-layout--box--right .ff-layout--box--content {
        @apply rounded-none md:rounded-xl m-auto;
    }
}